import React from "react";
import "../style/Hero.css";
import heroVideo from "../Video/Hero.mp4";
import heroImage from "../assets/HeroImage.png"; // Make sure to import your image
import DriverRegistration from "./DriverRegistration";

const Hero: React.FC = () => {
  return (
    <>
      <div className="hero">
        <video className="hero-video" autoPlay loop muted>
          <source src={heroVideo} type="video/mp4" />
        </video>
        <div className="hero-wrapper">
          <DriverRegistration />
        </div>
      </div>
      <div className="content-section">
        <div className="content-left">
          <h2>Start your journey with BBarive</h2>
          <p>
            Book a ride in minutes, Share your ride with others and save money
            on your commute.
          </p>
          <div className="store-buttons">
            <a
              href="https://apps.apple.com/your-app-link"
              target="_blank"
              rel="noopener noreferrer"
              className="app-store-button"
            >
              <img
                src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/your-app-link"
              target="_blank"
              rel="noopener noreferrer"
              className="play-store-button"
            >
              <img
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                alt="Get it on Google Play"
              />
            </a>
          </div>
        </div>
        <div className="content-right">
          <img src={heroImage} alt="BBarive App" className="hero-image" />
        </div>
      </div>
    </>
  );
};

export default Hero;
