import React from "react";
import "../style/DriverRegistration.css";

const DriverRegistration: React.FC = () => {
  return (
    <div className="registration-container">
      <div className="registration-form">
        <h3>Register as a Driver</h3>
        <div className="input-group">
          <input
            type="text"
            placeholder="Enter your email"
            className="register-input"
          />
          <button className="register-button">Register</button>
        </div>
      </div>
    </div>
  );
};

export default DriverRegistration;
