// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features {
    width: 100%;
    padding: 4rem 0;
    background-color: #f8f8f8;
}

.features-content {
    text-align: center;
    margin-bottom: 3rem;
}

.features-content h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding: 1rem;
}

.feature-item {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateY(-5px);
}

.feature-item h3 {
    margin-bottom: 1rem;
    color: #333;
}

.feature-item p {
    color: #666;
    line-height: 1.6;
}

@media screen and (max-width: 940px) {
    .features-content h2 {
        font-size: 2rem;
    }
    
    .feature-grid {
        grid-template-columns: 1fr;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/Features.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,qCAAqC;IACrC,+BAA+B;AACnC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".features {\n    width: 100%;\n    padding: 4rem 0;\n    background-color: #f8f8f8;\n}\n\n.features-content {\n    text-align: center;\n    margin-bottom: 3rem;\n}\n\n.features-content h2 {\n    font-size: 2.5rem;\n    margin-bottom: 2rem;\n}\n\n.feature-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    gap: 2rem;\n    padding: 1rem;\n}\n\n.feature-item {\n    background: white;\n    padding: 2rem;\n    border-radius: 10px;\n    box-shadow: 0 2px 5px rgba(0,0,0,0.1);\n    transition: transform 0.3s ease;\n}\n\n.feature-item:hover {\n    transform: translateY(-5px);\n}\n\n.feature-item h3 {\n    margin-bottom: 1rem;\n    color: #333;\n}\n\n.feature-item p {\n    color: #666;\n    line-height: 1.6;\n}\n\n@media screen and (max-width: 940px) {\n    .features-content h2 {\n        font-size: 2rem;\n    }\n    \n    .feature-grid {\n        grid-template-columns: 1fr;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
