import React from "react";
import { Link } from "react-router-dom";

import "../style/Features.css";

const Features = () => {
  return (
    <div className="features">
      <div className="container">
        <div className="features-content">
          <h2>Our Vision is to make commuting hassle-free and affordable</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <Link to="/quick-ride" className="feature-link">
                <h3>Quick Ride</h3>
              </Link>
              <p>
                Booking a ride is just a click away, Enter your pickup and
                destination to get a ride in minutes.
              </p>
            </div>
            <div className="feature-item">
              <Link to="/ridesharing" className="feature-link">
                <h3>Ridesharing</h3>
              </Link>
              <p>Share your ride with others and save money on your commute.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
