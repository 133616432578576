import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Footer from "./components/Footer";
import QuickRide from "./components/QuickRide";
import Ridesharing from "./components/Ridesharing";
import "./App.css";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Home page route with full layout */}
        <Route
          path="/"
          element={
            <div className="app">
              <Navbar />
              <Hero />
              <Features />
              <Footer />
            </div>
          }
        />

        {/* Quick Ride page with minimal layout */}
        <Route
          path="/quick-ride"
          element={
            <div className="app">
              <Navbar />
              <QuickRide />
              <Footer />
            </div>
          }
        />

        {/* Ridesharing page with minimal layout */}
        <Route
          path="/ridesharing"
          element={
            <div className="app">
              <Navbar />
              <Ridesharing />
              <Footer />
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
