import React from "react";
import "../style/QuickRide.css";

const QuickRide = () => {
  return (
    <div className="quick-ride">
      <div className="container">
        <h1>Quick Ride</h1>

        <div className="highlighted-features">
          <div className="highlight-card">
            <div className="highlight-icon">
              <img src="/images/local-ride.png" alt="Local Rides" />
            </div>
            <h3>Local Rides</h3>
            <p>Quick trips around your city</p>
          </div>

          <div className="highlight-card">
            <div className="highlight-icon">
              <img src="/images/intercity-ride.png" alt="Intercity Rides" />
            </div>
            <h3>Intercity Rides</h3>
            <p>Travel between cities comfortably</p>
          </div>

          <div className="highlight-card">
            <div className="highlight-icon">
              <img src="/images/airport-ride.png" alt="Airport Rides" />
            </div>
            <h3>Airport Rides</h3>
            <p>Reliable airport transfers</p>
          </div>
        </div>

        <div className="quick-ride-content">
          <div className="feature-details">
            <h2>Book Your Ride Instantly</h2>
            <p>
              Experience hassle-free commuting with our Quick Ride service. We
              provide a seamless booking experience with multiple benefits:
            </p>

            <div className="benefits-grid">
              <div className="benefit-card">
                <i className="fas fa-bolt"></i>
                <h3>Lightning Fast Booking</h3>
                <p>
                  Book your ride in less than 60 seconds with our streamlined
                  process. No waiting, no hassle.
                </p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-headset"></i>
                <h3>Book Recurring Rides</h3>
                <p>
                  Book recurring rides for your regular commute, making your
                  daily travel smoother and more convenient.
                </p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-location-dot"></i>
                <h3>Real-time Tracking</h3>
                <p>
                  Track your driver's location in real-time and share your trip
                  details with loved ones for added safety.
                </p>
              </div>

              <div className="benefit-card">
                <i className="fas fa-shield"></i>
                <h3>Safety First</h3>
                <p>
                  All drivers are background-checked and vehicles are regularly
                  inspected for your peace of mind.
                </p>
              </div>

              <div className="benefit-card">
                <i className="fas fa-credit-card"></i>
                <h3>Flexible Payments</h3>
                <p>
                  Choose from multiple payment options including credit cards,
                  digital wallets, and cash payments.
                </p>
              </div>
              <div className="benefit-card">
                <i className="fas fa-star"></i>
                <h3>Rated Drivers</h3>
                <p>
                  All our drivers are rated by customers to maintain high
                  service quality standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickRide;
