import React from "react";
import "../style/QuickRide.css";

const Ridesharing: React.FC = () => {
  return (
    <div className="quick-ride-page">
      <div className="hero-section">
        <img
          src="/path-to-your-hero-image.jpg"
          alt="Ridesharing Hero"
          className="hero-image"
        />
      </div>

      <div className="quick-ride">
        <div className="container">
          <div className="quick-ride-content">
            <div className="feature-details">
              <h2>Share Your Journey</h2>
              <p>
                Connect with fellow travelers heading in the same direction. Our
                ridesharing service makes commuting more affordable,
                sustainable, and social.
              </p>
            </div>

            <div className="benefits-grid">
              <div className="benefit-card">
                <i className="fas fa-wallet"></i>
                <h3>Cost-Effective</h3>
                <p>Split travel costs and save money on your daily commute</p>
              </div>

              <div className="benefit-card">
                <i className="fas fa-leaf"></i>
                <h3>Eco-Friendly</h3>
                <p>Reduce carbon emissions by sharing rides with others</p>
              </div>

              <div className="benefit-card">
                <i className="fas fa-shield-alt"></i>
                <h3>Safe & Secure</h3>
                <p>Verified users and secure payment systems</p>
              </div>
            </div>

            <div className="highlighted-features">
              <div className="highlight-card">
                <div className="highlight-icon">
                  <img src="/path-to-your-icon.png" alt="Flexible Schedule" />
                </div>
                <h3>Flexible Scheduling</h3>
                <p>Choose rides that match your daily routine</p>
              </div>

              <div className="highlight-card">
                <div className="highlight-icon">
                  <img src="/path-to-your-icon.png" alt="Community" />
                </div>
                <h3>Community Driven</h3>
                <p>Join a network of trusted commuters</p>
              </div>

              <div className="highlight-card">
                <div className="highlight-icon">
                  <img src="/path-to-your-icon.png" alt="Convenience" />
                </div>
                <h3>Easy to Use</h3>
                <p>Simple booking and ride management</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ridesharing;
