// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 0;
}

.logo-wrapper {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  height: 60px;
}

.logo {
  width: 155px;
  height: auto;
  transition: all 0.3s ease;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  object-fit: contain;
  padding: 3px;
}

.logo-wrapper:hover .logo {
  transform: scale(1.05);
  filter: drop-shadow(0 4px 8px rgba(100, 181, 246, 0.4));
}

.logo-wrapper.hovered::after {
  width: 85px;
  height: 85px;
}

@media (max-width: 768px) {
  .logo {
    width: 120px;
    height: auto;
    min-width: 80px;
  }
  
  .logo-wrapper {
    height: 60px;
    padding: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/Logo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,8CAA8C;EAC9C,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,uDAAuD;AACzD;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[".logo-container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  padding: 8px 0;\n}\n\n.logo-wrapper {\n  position: relative;\n  cursor: pointer;\n  transition: transform 0.3s ease;\n  display: flex;\n  align-items: center;\n  height: 60px;\n}\n\n.logo {\n  width: 155px;\n  height: auto;\n  transition: all 0.3s ease;\n  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));\n  object-fit: contain;\n  padding: 3px;\n}\n\n.logo-wrapper:hover .logo {\n  transform: scale(1.05);\n  filter: drop-shadow(0 4px 8px rgba(100, 181, 246, 0.4));\n}\n\n.logo-wrapper.hovered::after {\n  width: 85px;\n  height: 85px;\n}\n\n@media (max-width: 768px) {\n  .logo {\n    width: 120px;\n    height: auto;\n    min-width: 80px;\n  }\n  \n  .logo-wrapper {\n    height: 60px;\n    padding: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
