import React from "react";
import "../style/Footer.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-grid">
          {/* Company Info */}
          <div className="footer-content">
            <h4>Company</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              nemo minima qui dolor, iusto iure.
            </p>
            <div className="social">
              <a 
                href="https://facebook.com/yourpage" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaFacebook className="social-icon" />
              </a>
              
              <a 
                href="https://instagram.com/yourpage" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaInstagram className="social-icon" />
              </a>
              
              <a 
                href="https://linkedin.com/company/yourpage" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaLinkedin className="social-icon" />
              </a>
              
              <a 
                href="https://twitter.com/yourpage" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaXTwitter className="social-icon" />
              </a>

              <a 
                href="https://tiktok.com/@yourpage" 
                target="_blank" 
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaTiktok className="social-icon" />
              </a>
            </div>
          </div>

          {/* Solutions */}
          <div className="footer-content">
            <h4>Solutions</h4>
            <ul>
              <li><a href="/marketing">Marketing</a></li>
              <li><a href="/analytics">Analytics</a></li>
              <li><a href="/commerce">Commerce</a></li>
              <li><a href="/data">Data</a></li>
            </ul>
          </div>

          {/* Support */}
          <div className="footer-content">
            <h4>Support</h4>
            <ul>
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="/docs">Documentation</a></li>
              <li><a href="/guides">Guides</a></li>
              <li><a href="/status">API Status</a></li>
            </ul>
          </div>

          {/* Contact */}
          <div className="footer-content">
            <h4>Contact Us</h4>
            <ul>
              <li>info@bbarive.com</li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Company. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;